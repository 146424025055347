



















































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import snakeCaseKeys from 'snakecase-keys'
import { namespace } from 'vuex-class'

import EventSubscriptionTypeDropdown from '@/partials/forms/Events/EventSubscriptionTypeDropdown.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    ValidationProvider,
    EventSubscriptionTypeDropdown,
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class AddPersonToEventForm extends Mixins(FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true }) person!: EventPerson
  @Prop({ required: true }) role!: Role
  @sessionModule.Getter state!: any
  @sessionModule.Action login!: (login: login) => Promise<any>
  done = true

  search = ''
  invalidSearch = false
  firstSearch = true
  data = []
  filteredData = []
  selectModalIsOpened = false
  serviceRole = {
    id: '',
    name: ''
  }

  form: Record<string, any> = {
    eventSubscriptionTypeId: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  selectedUser = {
    id: ''
  }

  searchUsers () {
    if (this.search !== '') {
      this.done = false
      axios.get(`users?search=${this.search}`)
        .then(response => {
          console.log('response', response.data.data)
          this.filteredData = this.data = response.data.data
          this.firstSearch = false
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => { this.done = true })
    } else {
      this.invalidSearch = true
    }
  }

  filterUsers (searchString: string) {
    this.filteredData = this.data.filter((usuario: Record<string, any>) => {
      let { email, name } = usuario
      email = email.toLowerCase()
      name = name.toLowerCase()
      searchString = searchString.toLowerCase()

      if (email.includes(searchString) || name.includes(searchString)) {
        return usuario
      }
    })
  }

  submit () {
    const eventSubscriptionTypeId = this.form.eventSubscriptionTypeId === '' ? undefined : this.form.eventSubscriptionTypeId
    const submitForm = {
      users: [this.selectedUser.id],
      confirm: this.form.confirm,
      eventSubscriptionTypeId
    }

    axios.post(`/event/${this.eventId}/users/role/${this.role.id}/sync`, snakeCaseKeys(submitForm, { deep: true }))
      .then(async (response) => {
        const flashMessage: flashMessage = {
          message: response.data.data,
          type: 'success'
        }

        if (this.selectedUser.id === this.state.user.id) {
          // This is DEFINITIVELY GAMBICODE with Ricardo's blessing 🙏🏼
          await this.login({
            email: this.state.user.email,
            password: this.form.confirm
          })
        }

        this.selectModalIsOpened = false
        this.$nextTick(() => this.$emit('submitted', flashMessage))
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  get getNewUserRoute () {
    const routeDictionary = {
      speaker: 'CreateSpeakerToEvent',
      sponsor: 'CreateSponsorToEvent',
      subscriber: 'CreateSubscriberToEvent',
      studyReviewer: 'CreateStudyReviewerToEvent',
      organizer: 'CreateOrganizerToEvent',
      staff: 'CreateStaffToEvent'
    }

    return { name: routeDictionary[this.person], params: { id: this.eventId } }
  }
}
