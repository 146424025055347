












import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

@Component
export default class SpeakersDropdown extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String, required: true }) eventId!: string
  @Prop({ type: String }) invalidMessage!: string
  @Prop({ type: Boolean }) disabled!: boolean
  comboModel = ''
  eventSubscriptionTypes = []

  async mounted () {
    await axios.get(`/event/${this.eventId}/subscription/type/dropdown`)
      .then((response) => {
        const eventSubscriptionTypes = camelCaseKeys(response.data.data)

        this.eventSubscriptionTypes = eventSubscriptionTypes.map((subscriptionType: any) => {
          const option = {
            value: subscriptionType.id,
            name: subscriptionType.typePrice,
            label: subscriptionType.typePrice
          }
          return option
        })

        this.$nextTick(() => {
          this.comboModel = this.inputField
        })
      })
  }

  @Watch('comboModel')
  onComboModelChange () {
    this.inputField = this.comboModel
  }
}
